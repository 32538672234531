import React, { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import Layout from './Layout'
import ClipLoader from 'react-spinners/ClipLoader'
import { Link } from 'gatsby'

const RESET_PASSWORD = gql`
  mutation($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      clientMutationId
    }
  }
`

export default function CreatePassword(match) {

  const [step, setStep] = useState(1)
  const [error, setError] = useState()
  const [status, setStatus] = useState('')
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')

  useEffect(() => {
    console.log('key:');
    console.log(match.params.key);
    console.log('login');
    console.log(match.params.login);
  }, [match])

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: (res) => {
      console.log(res);
      console.log('Password successfully changed');
      setStep(2)
      setError(null)
      setStatus('')
    },
    onError: (err) => {
      console.log(err.message);
      setStatus('')
    }
  })

  const submit = (e) => {
    e.preventDefault();

    if (password !== confirm) {
      setError('Lösenorden stämmer inte överens. Försök igen.')
      return;
    }
    setStatus('resolving')
    resetPassword({
      variables: {
        input: {
          key: match.params.key,
          login: match.params.login,
          password: password
        }
      }
    }).then(() => e.target.reset())
  }

  return (
    <Layout>
      <div className="forgot-password">
        <h2>Återställ lösenord</h2>
        {step === 1 &&
          <form onSubmit={submit}>
            <div className="form-row">
              <label htmlFor="email">Nytt Lösenord</label>
              <input onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" autoComplete="off" required />
            </div>
            <div className="form-row">
              <label htmlFor="email">Bekräfta Lösenord</label>
              <input onChange={(e) => setConfirm(e.target.value)} type="password" name="confirm" id="confirm" autoComplete="off" required />
            </div>
            <button type="submit" disabled={status === 'resolving'}>Skicka</button>

            <div className="messages">
              {status === 'resolving' &&
                <div className="text-center spinner">
                  <ClipLoader size={22} color={"#ffffffbb"} />
                </div>
              }

              {error && (
                <div className="error-notice text-center">
                  <p>{error}</p>
                </div>
              )}

            </div>
          </form>
        }
        {step === 2 &&
          <div>
            <p>Ditt lösenord har nu ändrats.</p>
            <Link to="/login" className="btn">
              <button>
                Logga in
              </button>
            </Link>
          </div>
        }
      </div>
    </Layout>
  )
}

